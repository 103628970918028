import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import {Observable} from 'rxjs';

import { ConstantService } from '../../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  
  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  get(param: Array<object>) {
    if (!param.length) return this.http.get(this.constantService.getUrl(this.constantService.REGION));
    else return this.http.get(this.constantService.getUrlByQuery(this.constantService.REGION, param));
  }

  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.REGION, [id]));
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.REGION), body);
  }

  put(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.REGION, [id]), body);
  }

  patch(id) {
    return this.http.patch(this.constantService.getUrl(`${this.constantService.REGION}/${id}/activate`), {});
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.REGION + '/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    })
  }

}
