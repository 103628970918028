import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  private _items: any = {};
  
  setItem(item: any) {
    this._items = item;
  }

  getItems() {
    return this._items;
  }
    
  get(params) {
    return this.http.get(this.constantService.getUrlByQuery(this.constantService.RBACROLE, params));
  }

  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.RBACROLE, [id]));
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.RBACROLE), body);
  }

  put(id: string, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.RBACROLE, [id]), body);
  }

  deleteRole(id: number) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.RBACROLE}/${id}`));
  }

}
