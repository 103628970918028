import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { ConstantService } from '../../constant/constant.service';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    constructor(
        private http: HttpClient,
        private constantService: ConstantService
    ) { }

    getOrganizationList(params? :any) {
        return this.http.get(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization`, params));
    }

    getOneOrganization(id) {
        return this.http.get(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}`));
    }

    updateOrganization(id, body) {
        return this.http.put(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}`), body);
    }

    addNewOrganization(body) {
        return this.http.post(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization`), body);
    }

    toggleDisable(id) {
        return this.http.patch(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}`),'');
    }
    
    uploadTermsAndConditionsTemplate(id, body) {
        return this.http.post(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/upload/termsAndConditionsTemplate`), body);
    }

    getTermsAndConditionsTemplate(id) {
        return this.http.get(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/termsAndConditionsTemplate`));
    }

    uploadWelcomeLetterTemplate(id, body) {
        return this.http.post(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/upload/welcomeLetterTemplate`), body);
    }

    getWelcomeLetterTemplate(id) {
        return this.http.get(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/welcomeLetterTemplate`));
    }

    getOrganizationTemplate(id) {
        return this.http.get(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/templates`));
    }

    updateTemplate(id, body) {
        return this.http.put(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/templates`), body);
    }

    addTemplate(id, body) {
        return this.http.post(this.constantService.getUrl(this.constantService.WORKFLOW + `/organization/${id}/templates`), body);
    }
}
